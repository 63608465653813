import QueueRecorder from './QueueRecorder';
import { infoLog } from '@bit/gerenciatd.utils.log-utils';

class Core {
  constructor() {
    this._attachEvent();
  }

  _attachEvent() {
    // add an appropriate event listener
    document.addEventListener('nookInfoEvent', (e) => {
      const { nookMessage } = e.detail;

      if (nookMessage) {
        const { queueName, message } = nookMessage;

        this.addMessage(queueName, message);
      }

    });
  }

  registerAsObserver(queueName, observerName, callback, allUpdates = true) {
    let queue = QueueRecorder.getQueue(queueName);

    if (!queue) {
      queue = QueueRecorder.register(queueName);
    }

    queue.addObserver(observerName, callback, allUpdates);
  }

  cleanObserver(queueName, observerName) {
    let queue = QueueRecorder.getQueue(queueName);

    if (!queue) {
      infoLog(`You're trying to clean observer ${observerName} from unexisting queue (${queueName})`);
    } else {
      queue.cleanObserver(observerName);
    }
  }

  cleanQueue(queueName) {
    let queue = QueueRecorder.getQueue(queueName);

    if (!queue) {
      infoLog(`You're trying to clean an unexisting queue (${queueName})`);
    } else {
      queue.cleanQueue();
    }
  }

  addMessage(queueName, msg) {
    let queue = QueueRecorder.getQueue(queueName);

    if (!queue) {
      queue = QueueRecorder.register(queueName);
    }

    queue.push(msg);
  }

  sendMessage(queueName, message) {
    var event = new CustomEvent('nookInfoEvent', {
      detail: {
        nookMessage: {
          queueName: queueName,
          message: message
        }
      }
    });

    document.dispatchEvent(event);
  }

}

export default Core;
