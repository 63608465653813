import Core from './Core/Core';

(function (window) {
  if (!window.nookInfoExchange) {
    window.nookInfoExchange = new Core();
  }
})(window);

// IE Custom Event polyfill
(function (window) {
  if (typeof window.CustomEvent === 'function') {
    return false;
  }

  function CustomEvent(event, params) {
    var evt = document.createEvent('CustomEvent');
    const parameters = params || { bubbles: false, cancelable: false, detail: undefined };

    evt.initCustomEvent(event, parameters.bubbles, parameters.cancelable, parameters.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;

  return true;
})(window);
