import { infoLog } from '@bit/gerenciatd.utils.log-utils';

// Queue class
class Queue {
  // Array is used to implement a Queue
  constructor() {
    this.items = [];
    this.observers = {};
  }

  addObserver(observerName, callback, allUpdates) {
    if (this.observers[observerName]) {
      infoLog('You can only add observers once');
      return false;
    }

    this.observers[observerName] = callback;

    if (allUpdates) {
      for (;;) {
        const newItem = this.get(observerName);

        if (newItem) {
          callback(newItem);
        } else {
          break;
        }
      }
    } else {
      let newItem = null;

      for (;;) {
        const nextItem = this.get(observerName);

        if (!nextItem) {
          if (newItem) {
            callback(newItem);
          }
          break;
        } else {
          newItem = nextItem;
        }
      }
    }

    return true;
  }

  cleanObserver(observerName) {
    if (!this.observers[observerName]) {
      infoLog(`You are trying to clean an unexisting observer (${observerName})`);
      return false;
    }

    delete this.observers[observerName];

    return true;
  }

  cleanQueue() {
    this.items = [];
  }

  // enqueue(item)
  push(element) {
    // adding element to the queue
    const queueItem = {
      item: element,
      readBy: []
    };

    Object.keys(this.observers).forEach(observerName => {
      const callback = this.observers[observerName];

      callback(queueItem.item);
      queueItem.readBy.push(observerName);
    });

    this.items.push(queueItem);
  }
  // dequeue()
  pop() {
    // removing element from the queue
    // returns underflow when called
    // on empty queue
    if (this.isEmpty()) {
      return 'Underflow';
    }

    const queueItem = this.items.shift();

    return queueItem.item;
  }
  // pop()
  get(observerName) {
    let itemToReturn = null;

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].readBy.indexOf(observerName) === -1) {
        itemToReturn = this.items[i];
        this.items[i].readBy.push(observerName);
        break;
      }
    }

    if (!itemToReturn) {
      return null;
    }

    return itemToReturn.item;
  }
  // isEmpty()
  isEmpty(observerName) {
    // return true if the queue is empty.
    const isEmpty = true;

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].readBy.indexOf(observerName) !== -1) {
        isEmpty = false;
        break;
      }
    }

    return isEmpty;
  }
  // printQueue()
  printQueue() {
    let str = '';

    for (let i = 0; i < this.items.length; i++) {
      str = `${str}${this.items[i].item} `;
    }
    return str;
  }
}

export default Queue;
