import Queue from './Queue';
import { infoLog } from '@bit/gerenciatd.utils.log-utils';

class QueueRecorder {
  constructor() {
  }
  static register(queueName) {
    if (!QueueRecorder._registeredQueues.has(queueName)) {
      const newQueue = new Queue();

      QueueRecorder._registeredQueues.set(queueName, newQueue);

      return newQueue;
    }
    infoLog('Queue already registered.');
    return null;
  }

  static getQueue(queueName) {
    if (!QueueRecorder._registeredQueues.has(queueName)) {
      infoLog('You can not get a non-existent queue');
      return null;
    }
    const queue = this._registeredQueues.get(queueName);

    return queue;
  }
}
QueueRecorder._registeredQueues = new Map();

export default QueueRecorder;
